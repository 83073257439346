import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import useResponsive from '../../hooks/responsive.hook';
import Logo from '../common/logo.component';
import Scrollbar from '../common/scrollbar.component';
import NavItems from './sidebar/nav-items.component';
import ROUTE_PATH from '../../config/routes.config';
import { APP_BACKGROUND } from '../../theme/theme-colors';
import { checkAdminUserRole } from '../../utils/local-cache.util';
// import { getDataList } from '../../services/content.service';

const NAV_WIDTH = 280;

const Sidebar = ({ openNav, onCloseNav, rerenderComponent }) => {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [categoriesTab, setCategoriesTab] = useState([]);

  const adminUserRole = checkAdminUserRole();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  // If you want show dynamic tab feature then Uncomment API call and remove line 37 (setCategoriesTab)
  useEffect(() => {
    // getDataList('tabs')
    //   .then((res) => {
    //     setCategoriesTab(res.data?.rows);
    //   })
    //   .catch(() => {
    //     // to do
    //   });
    setCategoriesTab([]);
  }, [rerenderComponent]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3.5, display: 'inline-flex' }}>
        <NavLink
          to={
            adminUserRole === 'ADMIN'
              ? ROUTE_PATH.DASHBOARD
              : ROUTE_PATH.CONTENT_VIDEOS
          }
          style={{
            width: 125,
            margin: '0px auto',
          }}
        >
          <Logo />
        </NavLink>
      </Box>

      <NavItems categoriesTab={categoriesTab} adminUserRole={adminUserRole} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: APP_BACKGROUND,
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: APP_BACKGROUND,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
};

Sidebar.propTypes = {
  openNav: PropTypes.bool.isRequired,
  onCloseNav: PropTypes.func.isRequired,
  rerenderComponent: PropTypes.func,
};

Sidebar.defaultProps = {
  rerenderComponent: () => {},
};

export default Sidebar;
