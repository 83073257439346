// Define flags
export const DEFAULT_CHANNEL = true;
export const DEFAULT_CHANNEL_VALUE = 1;
export const MULTIPLE_CAT_PER_VIDEO = false;
export const SHOW_OVERVIEW_DASHBOARD = true;
export const SHOW_REALTIME_DASHBOARD = false;
export const SHOW_SUBSCRIBERS = false;
export const SHOW_ADMIN_USERS = true;
export const SHOW_EPG = true;
export const SHOW_CONTENT_CATEGORIES = true;
export const SUBCONTENT_CATEGORIES = false;
export const SHOW_CONTENT_VIDEOS = true;
export const SUBCONTENT_SEQUENCE = false;
export const SHOW_TABS = false;
export const SHOW_MANAGE_SEQUENCE = true;
export const SHOW_REPORTS_OVERVIEW_VIDEOS = false;
export const SHOW_REPORTS_OVERVIEW_ADS = false;
export const SHOW_REPORTS_LIVE_USERS = false;
export const SHOW_REPORTS_WATCH_SESSIONS = false;
export const SHOW_REPORTS_APP_SESSIONS = true;

// Define commonly used constants
export const LS_ADMIN_USER_ID = '__gan_admin_i';
export const LS_ADMIN_USER_AUTH_TOKEN = '__gan_admin_t';
export const LS_ADMIN_USER_NAME = '__gan_admin_n';
export const LS_ADMIN_USER_EMAIL = '__gan_admin_e';
export const LS_ADMIN_USER_ROLE = '__gan_admin_r';

export const STATUS_LABELS = {
  0: 'Inactive',
  1: 'Active',
  2: 'Deleted',
  5: 'Requested Delete',
  11: 'Pending Verification',
  12: 'Cancellation Applied',
};

export const STATUS_COLORS = {
  0: 'error',
  1: 'success',
  2: 'error',
  5: 'warning',
  11: 'warning',
  12: 'warning',
};

export const CONTENT_ALIAS = {
  CHANNEL_SINGULAR: 'Channel',
  CHANNEL_PLURAL: 'Channels',
  CATEGORY_SINGULAR: 'Category',
  CATEGORY_PLURAL: 'Categories',
  VIDEO_SINGULAR: 'Video',
  VIDEO_PLURAL: 'Videos',
  AD_SINGULAR: 'Ad',
  AD_PLURAL: 'Ads',
};

export const CATEGORY_OPTIONS = {
  old: {
    shorthand: 'old',
    title: 'OLD TESTAMENT STUDY',
    displayText: 'Old Testament',
    path: 'old',
  },
  new: {
    shorthand: 'new',
    title: 'NEW TESTAMENT STUDY',
    displayText: 'New Testament',
    path: 'new',
  },
};

export const ADMIN_USER_ROLE = {
  ADMIN: 'ADMIN',
  CONTENT_OWNER: 'CONTENT_OWNER',
};

export const ACTIONS = {
  CREATE: {
    value: 'create',
    label: 'Create New',
  },
  EDIT: {
    value: 'update',
    label: 'Edit',
  },
  VIEW: {
    value: 'view',
    label: 'View',
  },
  DELETE: {
    value: 'delete',
    label: 'Delete',
  },
  CHANGE_PASSWORD: {
    value: 'change_password',
    label: 'Change Password',
  },
  ASSIGN_CATEGORIES: {
    value: 'assign_books',
    label: `Assign ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  },
  UPLOAD_ASSETS: {
    value: 'upload_assets',
    label: 'Upload Assets',
  },
};

// Place it in CSS file
export const FOCUS_PALETTE_BGCOLOR = 'rgba(255, 255, 255, 0.25)';

export const EPG_SCHEDULER_GAP_HEIGHT = 80;

// If you change one block timing gap (currently 30 minutes) then you also need change below variable value
export const TIME_GAP_OF_EPG_BLOCK_IN_SECONDS = 1799;
