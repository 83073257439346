import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions,
  Button,
  Grid,
  Box,
  ImageList,
  ImageListItem,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Dropzone from 'react-dropzone';
import { validateSelectedImageFile } from '../../utils/file-validations.util';
import { uploadImageToBunnyCDN } from '../../services/content.service';

const ImageUpload = ({
  title,
  dataId,
  showToastMsg,
  setSnackbarInfo,
  videoData,
  loading,
  showUpdatedImage,
}) => {
  const [selectedFileName, setSelectedFileName] = useState('');
  const [selectedFilesObj, setSelectedFilesObj] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showUploading, setShowUploading] = useState(false);

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  // eslint-disable-next-line no-promise-executor-return
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleSelectFiles = async (files) => {
    showUpdatedImage(false);
    setSelectedFilesObj(files);
    setBtnDisabled(false);
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedImageFile(files);
    if (validationError) {
      showToastMsg('error', validationError);
    }

    // Show file name below button
    for (let i = 0; i < files.length; i += 1) {
      setSelectedFileName(files[i].name);
    }
  };

  const handleUploadFiles = async (videoId, selectedImageFileName) => {
    setSnackbarInfo({
      show: false,
    });
    await sleep(100);

    const validationError = validateSelectedImageFile(selectedFilesObj);
    if (validationError) {
      showToastMsg('error', validationError);
    } else {
      setBtnDisabled(true);
      setShowUploading(true);
      const base64 = await convertBase64(selectedFilesObj[0]);

      const payload = {
        videoId,
        imageFile: base64,
        imageType: title,
        selectedImageFileName,
      };
      try {
        const cdnResponse = await uploadImageToBunnyCDN(payload);
        if (cdnResponse.data?.success) {
          showUpdatedImage(true);
          showToastMsg('success', `${title} Uploaded Successfully!`);
        } else {
          showToastMsg('error', 'Something went wrong! Please try again.');
        }
      } catch {
        showToastMsg('error', 'Something went wrong! Please try again.');
      }

      setShowUploading(false);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        sx={{
          whiteSpace: 'unset',
          wordBreak: 'break-all',
        }}
      >
        <Grid item sx={{ fontWeight: 'bold' }}>
          {`${title}:`}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          {loading && (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          )}

          {!loading && (
            <ImageList>
              <ImageListItem>
                {videoData.thumbnail && title === 'Thumbnail' && (
                  <img
                    src={videoData.thumbnail}
                    alt={title}
                    loading="lazy"
                    style={{ objectFit: 'contain' }}
                  />
                )}
                {videoData.heroImage && title === 'Hero Image' && (
                  <img
                    src={videoData.heroImage}
                    alt={title}
                    loading="lazy"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </ImageListItem>
            </ImageList>
          )}
        </Grid>
        <Grid item>
          <Box sx={{ fontWeight: 'bold' }}>{`${title} URL:`}&nbsp;</Box>
          <Box sx={{ fontSize: '15px' }}>
            {title === 'Thumbnail' ? videoData.thumbnail : videoData.heroImage}
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Grid item xs={12} sm={12} md={12}>
          <Dropzone
            onDrop={(acceptedFiles) => handleSelectFiles(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <Box
                    component="section"
                    sx={{
                      p: 3,
                      border: '1px dashed grey',
                      borderRadius: '20px',
                      width: '100%',
                      marginTop: 3,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 'bold',
                        }}
                      >
                        {`Add/Replace ${title}`}
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{ display: 'flex', justifyContent: 'center' }}
                        >
                          Drag and drop image files here, or click to browse
                        </Grid>

                        <DialogActions sx={{ justifyContent: 'center' }}>
                          {!showUploading && (
                            <Button
                              component="label"
                              variant="contained"
                              sx={{
                                backgroundColor: '#808080',
                                color: 'white',
                                '&:hover': {
                                  color: 'black',
                                },
                              }}
                            >
                              {`Select ${title}`}
                            </Button>
                          )}

                          {showUploading && (
                            <LoadingButton
                              size="medium"
                              color="primary"
                              loading
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                            >
                              <span>Uploading...</span>
                            </LoadingButton>
                          )}
                        </DialogActions>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {`${selectedFileName}`}
                    </Grid>
                  </Box>
                </div>
              </section>
            )}
          </Dropzone>
        </Grid>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            color="primary"
            variant="contained"
            disabled={btnDisabled}
            onClick={() => handleUploadFiles(dataId, selectedFileName)}
          >
            {`Upload ${title}`}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

ImageUpload.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  showToastMsg: PropTypes.func.isRequired,
  setSnackbarInfo: PropTypes.object.isRequired,
  videoData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showUpdatedImage: PropTypes.func.isRequired,
};

export default ImageUpload;
