import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListAppSessions from '../../components/reports/list-app-sessions.component';

const ReportsAppSessionsView = () => {
  const pageTitle = PAGE_TITLE_LIST.REPORTS_APP_SESSIONS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListAppSessions />
    </AppLayout>
  );
};

export default ReportsAppSessionsView;
