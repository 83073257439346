import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from '../theme/wrappers';
import AppRouter from '../routes/app.routes';
import {
  checkAdminUserLoggedIn,
  setAdminUserLoggedOut,
  setAdminUserLoggedIn,
} from '../utils/local-cache.util';
import { adminUserValidateSession } from '../services/auth.service';
import Loader from '../components/common/loader.component';
// import { getDataList } from '../services/content.service';

const AppView = () => {
  const [appLoaded, setAppLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [categoriesTab, setCategoriesTab] = useState([]);
  const [counter, setCounter] = useState(0);

  const rerenderComponent = () => {
    setCounter(counter + 1);
  };

  // If you want show dynamic tab feature then Uncomment API call and remove line 34 (setCategoriesTab)
  useEffect(() => {
    // getDataList('tabs')
    //   .then((res) => {
    //     setCategoriesTab(res.data?.rows);
    //   })
    //   .catch(() => {
    //     // to do
    //   });
    setCategoriesTab([]);
  }, [counter]);

  useEffect(() => {
    const isAdminLoggedIn = checkAdminUserLoggedIn();
    if (isAdminLoggedIn) {
      adminUserValidateSession()
        .then((res) => {
          const loggedInUserPayload = {
            id: res.data?.id || null,
            token: res.data?.auth?.token || '',
            name: res.data?.name || '',
            email: res.data?.email || '',
            role: res.data?.role || '',
          };
          setAdminUserLoggedIn(loggedInUserPayload);
          setIsLoggedIn(isAdminLoggedIn);
          setAppLoaded(true);
        })
        .catch(() => {
          setAdminUserLoggedOut();
          setAppLoaded(true);
        });
    } else {
      setAppLoaded(true);
    }
  }, []);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          {!appLoaded && <Loader />}
          {appLoaded && (
            <AppRouter
              isLoggedIn={isLoggedIn}
              categoriesTab={categoriesTab}
              rerenderComponent={rerenderComponent}
            />
          )}
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default AppView;
