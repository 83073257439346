import React, { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import Datepicker from '../form-elements/datepicker.component';
import Timepicker from '../form-elements/timepicker.component';
import {
  createNewEpg,
  updateEpgById,
  viewEpgById,
} from '../../services/epg.service';
import {
  convertDateStringToTimestamp,
  formatDateToUtc,
  formatTimestampToUtc,
} from '../../utils/datetime.util';
import { timeScheduler24Hours } from '../../config/time-scheduler.config';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditEpg = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  onError,
  filters,
  details,
  deleteAction,
  droppableId,
  conflictPopupCallback,
  passedStartTime,
}) => {
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      date: null,
      actualDuration: null,
      startTime: null,
      endTime: null,
      scheduledDuration: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [epgProgramData, setEpgProgramData] = useState({});
  const [changedTime, setChangedTime] = useState({
    startTime: null,
    endTime: null,
  });
  const startTime = useRef(null);
  const endTime = useRef(null);

  const autoFillStartTime = !passedStartTime
    ? formatDateToUtc(
        `${filters.date.toString().substring(0, 16)}${
          timeScheduler24Hours[droppableId - 1]
        }:00${filters.date.toString().substring(24)}`
      )
    : passedStartTime;

  const convertDurationToEndTime = () => {
    const videoDuration = details.videoData?.duration;
    const getMinutes = Math.floor(videoDuration / 60);
    const getHours = Math.floor(getMinutes / 60);
    const getSeconds = videoDuration % 60;
    const extraHours = getMinutes % 60;

    let finalHours = Number(autoFillStartTime.slice(11, 13)) + Number(getHours);
    let finalMinutes =
      Number(autoFillStartTime.slice(14, 16)) + Number(extraHours);
    let finalSeconds =
      Number(autoFillStartTime.slice(17, 19)) + Number(getSeconds);

    let updatedMinutes;
    let extraSeconds;
    if (finalSeconds > 60) {
      updatedMinutes = Math.floor(finalSeconds / 60);
      finalMinutes += updatedMinutes;

      extraSeconds = finalSeconds % 60;
      finalSeconds = extraSeconds;
    }

    let updatedHours;
    let extraMinutes;
    if (finalMinutes > 60) {
      updatedHours = Math.floor(finalMinutes / 60);
      finalHours += updatedHours;

      extraMinutes = finalMinutes % 60;
      finalMinutes = extraMinutes;
    }

    return `${finalHours < 10 ? `0${finalHours}` : finalHours}:${
      finalMinutes < 10 ? `0${finalMinutes}` : finalMinutes
    }:${finalSeconds < 10 ? `0${finalSeconds}` : finalSeconds}+00:00`;
  };

  const calculatePassedEndTime = formatTimestampToUtc(
    new Date(passedStartTime).getTime() / 1000 + details.videoData.duration
  );

  const autoFillEndTime = !passedStartTime
    ? autoFillStartTime.slice(0, 11) + convertDurationToEndTime()
    : calculatePassedEndTime;

  const handleStartTimeChange = (date) => {
    startTime.current = date.$d.toString().slice(16);
    setChangedTime({
      ...changedTime,
      startTime: new Date(date.$d).getTime() / 1000,
    });
  };

  const handleEndTimeChange = (date) => {
    endTime.current = date.$d.toString().slice(16);
    setChangedTime({
      ...changedTime,
      endTime: new Date(date.$d).getTime() / 1000,
    });
  };

  const calculateScheduledProgrmaDuration = () => {
    let scheduledStartTime;
    let scheduledEndTime;

    if (!dataId) {
      scheduledStartTime = changedTime.startTime
        ? changedTime.startTime
        : new Date(autoFillStartTime).getTime() / 1000;
      scheduledEndTime = changedTime.endTime
        ? changedTime.endTime
        : new Date(autoFillEndTime).getTime() / 1000;
    } else {
      scheduledStartTime = changedTime.startTime
        ? changedTime.startTime
        : startTime.current;
      scheduledEndTime = changedTime.endTime
        ? changedTime.endTime
        : endTime.current;
    }

    const scheduledDuration = scheduledEndTime - scheduledStartTime;
    return scheduledDuration;
  };

  const saveNewData = (payload) => {
    createNewEpg(payload)
      .then((res) => {
        if (res.data.error) {
          setFormSubmitted(false);
          conflictPopupCallback(payload);
        } else if (res.data.success) {
          setFormSubmitted(true);
          onSuccess('EPG scheduled successfully.');
        }
      })
      .catch((e) => {
        onError(
          e.response?.data?.message || 'Something went wrong. Please try again!'
        );
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateEpgById(dataId, payload)
      .then((res) => {
        if (res.data.error) {
          setFormSubmitted(false);
          conflictPopupCallback(payload, dataId);
        } else if (res.data.success) {
          setFormSubmitted(true);
          onSuccess('EPG details updated successfully.');
        }
      })
      .catch((e) => {
        onError(
          e.response?.data?.message || 'Something went wrong. Please try again!'
        );
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);

    // Calculate program local start date
    let programStartTime;
    if (dataId) {
      const editedStartDateOfProgram = startTime.current
        ? data.date.toString().slice(0, 16) + startTime.current
        : new Date(data.startTime);

      programStartTime = editedStartDateOfProgram;
    } else {
      const autoFillStartDate = new Date(autoFillStartTime);

      programStartTime = startTime.current
        ? autoFillStartDate.toString().slice(0, 16) + startTime.current
        : autoFillStartDate;
    }

    // Calculate program local end date
    let programEndTime;
    if (dataId) {
      const editedEndDateOfProgram = endTime.current
        ? data.date.toString().slice(0, 16) + endTime.current
        : new Date(data.endTime);

      programEndTime = editedEndDateOfProgram;
    } else {
      const autoFillEndDate = new Date(autoFillEndTime);

      programEndTime = endTime.current
        ? autoFillEndDate.toString().slice(0, 16) + endTime.current
        : autoFillEndDate;
    }

    // Convert local date to timestamp
    const localStartTimeStamp =
      typeof startTime.current === 'number'
        ? startTime.current
        : convertDateStringToTimestamp(programStartTime);
    const localEndTimeStamp =
      typeof endTime.current === 'number'
        ? endTime.current
        : convertDateStringToTimestamp(programEndTime);

    // Save/update EPG data
    const payload = {
      duration: localEndTimeStamp - localStartTimeStamp,
      startTime: localStartTimeStamp,
    };
    if (dataId) {
      payload.channelId = 1;
      payload.endTime = localEndTimeStamp;

      updateExistingData(payload);
    } else {
      payload.channelId = details.channelId;
      payload.videoId = details.videoId;
      payload.endTime = localEndTimeStamp;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewEpgById(dataId)
      .then((res) => {
        setEpgProgramData(res.data);
        const date = new Date(res.data?.startTime);

        setValue('date', date || null);
        setValue('actualDuration', res.data?.actualVideoDuration || 0);
        setValue('startTime', res.data?.startTime || null);
        setValue('endTime', res.data?.endTime || null);
        setValue('scheduledDuration', res.data?.duration || 0);
        startTime.current = new Date(res.data.startTime).getTime() / 1000;
        endTime.current = new Date(res.data.endTime).getTime() / 1000;

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <b>Channel Name: </b>
                {`${
                  !dataId
                    ? details.channelData?.title
                    : epgProgramData.channelName
                }`}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <b>Program Title: </b>
                {`${
                  !dataId ? details.videoData?.title : epgProgramData.videoTitle
                }`}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <b>Category Name: </b>
                {`${
                  !dataId
                    ? (details.videoData?.category &&
                        details.videoData?.category[0]?.categoryName) ||
                      '-'
                    : epgProgramData.categoryName || '-'
                }`}
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <b>Tags: </b>
                {`${
                  !dataId
                    ? details.videoData?.tags || '-'
                    : epgProgramData.tags || '-'
                }`}
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <b>
                  Date <br />
                </b>
                <span style={{ fontSize: '15px' }}>
                  Note: Play Program/Ad on this date.
                </span>
                <Controller
                  name="date"
                  control={control}
                  render={({ field: { value } }) => (
                    <Datepicker
                      id="epg_date"
                      name="epg_date"
                      label="Date"
                      defaultValue={!dataId ? filters.date : value}
                      isDisabled
                      sx={{ width: 300 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} />

              <Grid item xs={12} sm={4} md={4}>
                <b>
                  Actual Video Duration <br />
                </b>
                <span style={{ fontSize: '15px' }}>
                  Note: This is an actual video duration.
                </span>
                <Controller
                  name="actualDuration"
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      value={!dataId ? details.videoData?.duration : value}
                      type="text"
                      sx={{ width: 300, marginTop: 1 }}
                      disabled
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <b>
                  Start Time (Local Time) <br />
                </b>
                <span style={{ fontSize: '15px' }}>
                  Note: Select start time in 24 hour format.
                </span>
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field: { value } }) => (
                    <Timepicker
                      id="epg_start_time"
                      name="epg_start_time"
                      label="Start Time"
                      value={!dataId ? dayjs(autoFillStartTime) : dayjs(value)}
                      onChange={handleStartTimeChange}
                      sx={{ width: 300 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <b>
                  End Time (Local Time) <br />
                </b>
                <span style={{ fontSize: '15px' }}>
                  Note: Select end time in 24 hour format.
                </span>
                <Controller
                  name="endTime"
                  control={control}
                  render={({ field: { value } }) => (
                    <Timepicker
                      id="epg_end_time"
                      name="epg_end_time"
                      label="End Time"
                      value={!dataId ? dayjs(autoFillEndTime) : dayjs(value)}
                      onChange={handleEndTimeChange}
                      sx={{ width: 300 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <b>
                  Scheduled Program Duration <br />
                </b>
                <span style={{ fontSize: '12px' }}>
                  Note: End time will be calculated based on start time and
                  duration.
                </span>
                <Controller
                  name="scheduledDuration"
                  control={control}
                  render={({ field: { value } }) => (
                    <TextField
                      value={
                        value && !changedTime.startTime && !changedTime.endTime
                          ? value
                          : calculateScheduledProgrmaDuration()
                      }
                      type="text"
                      sx={{ width: 300, marginTop: 1 }}
                      disabled
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'space-between',
          marginLeft: 2,
          marginRight: 2,
        }}
      >
        <Box>
          <Button
            type="submit"
            form="add-edit-form"
            color="primary"
            variant="contained"
            disabled={loading || formSubmitted}
            sx={{ marginRight: 1 }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={onCancel}
            disabled={formSubmitted}
            sx={{ marginRight: 1 }}
          >
            Cancel
          </Button>
        </Box>
        {dataId !== 0 && (
          <Box>
            <Button
              color="error"
              variant="contained"
              onClick={() => deleteAction()}
              disabled={formSubmitted}
            >
              Delete
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

AddEditEpg.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  deleteAction: PropTypes.func,
  droppableId: PropTypes.number,
  conflictPopupCallback: PropTypes.func.isRequired,
  passedStartTime: PropTypes.object.isRequired,
};
AddEditEpg.defaultProps = {
  deleteAction: () => {},
  droppableId: null,
};

export default AddEditEpg;
