import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListSequence from '../../components/content/list-sequence.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentSequenceView = ({ categoryType, categoryName }) => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_SEQUENCE}${
    categoryType ? ` - ${categoryName}` : ''
  }`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListSequence categoryType={categoryType} />
    </AppLayout>
  );
};

ContentSequenceView.propTypes = {
  categoryType: PropTypes.string,
  categoryName: PropTypes.string,
};
ContentSequenceView.defaultProps = {
  categoryType: '',
  categoryName: '',
};

export default ContentSequenceView;
