import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListUsers from '../../components/admin-users/list-users.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const AdminUsersView = () => {
  const pageTitle = PAGE_TITLE_LIST.ADMIN_USERS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListUsers />
    </AppLayout>
  );
};

export default AdminUsersView;
