import React from 'react';
import PropTypes from 'prop-types';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

const Datepicker = ({
  id,
  name,
  label,
  defaultValue,
  onChange,
  sx,
  error,
  isDisabled,
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoContainer components={['DatePicker']}>
      <DatePicker
        id={id}
        name={name}
        label={label}
        defaultValue={defaultValue ? dayjs(defaultValue) : defaultValue}
        onChange={onChange}
        sx={sx}
        slotProps={{
          textField: {
            error: error !== '',
            helperText: error,
          },
        }}
        disabled={isDisabled}
      />
    </DemoContainer>
  </LocalizationProvider>
);

Datepicker.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
};
Datepicker.defaultProps = {
  sx: {},
  defaultValue: null,
  error: '',
  isDisabled: false,
};

export default Datepicker;
