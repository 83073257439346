import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { VIDEOJS_OPTIONS } from '../../config/module-configs/videos.config';

const Player = ({ videoUrl, height, width, autoplay }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [showPlayerError, setShowPlayerError] = useState(false);

  let player = null;
  const playerOptions = VIDEOJS_OPTIONS;

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = window.document.createElement('video-js');
      videoElement.id = 'video-player';
      videoElement.classList.add('vjs-default-skin');
      videoElement.classList.add('vjs-big-play-centered');

      videoRef.current.appendChild(videoElement);

      playerRef.current = window.videojs(videoElement, {
        ...playerOptions,
        sources: [{ src: videoUrl }],
        height,
        width,
        autoplay,
      });

      player = playerRef.current;
    } else {
      player = playerRef.current;

      player.autoplay(autoplay);
      player.src(playerOptions.sources);
    }

    player.on('error', () => setShowPlayerError(true));
  }, [videoRef]);

  useEffect(() => {
    player = playerRef.current;

    return () => {
      player.dispose();
      playerRef.current = null;
    };
  }, [playerRef]);

  return (
    <>
      {!showPlayerError && (
        <div className="data-vjs-player">
          <div ref={videoRef} />
        </div>
      )}

      {showPlayerError && (
        <div
          style={{
            height,
            width,
            backgroundColor: 'black',
            color: 'white',
            fontSize: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No Preview Available
        </div>
      )}
    </>
  );
};

Player.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  autoplay: PropTypes.bool.isRequired,
};

export default Player;
