import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const Loading = ({ style, loaderColor }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 400,
      height: '100%',
    }}
    style={style}
  >
    <CircularProgress disableShrink sx={{ color: loaderColor }} />
  </Box>
);

Loading.propTypes = {
  style: PropTypes.object,
  loaderColor: PropTypes.string,
};
Loading.defaultProps = {
  style: {},
  loaderColor: '',
};

export default Loading;
