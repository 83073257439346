import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListEpg from '../../components/epg/list-epg.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const EpgView = () => {
  const pageTitle = PAGE_TITLE_LIST.EPG;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListEpg />
    </AppLayout>
  );
};

export default EpgView;
