import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Watch sessions module configurations
export const LISTING_COLUMNS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    width: '10%',
    align: 'left',
    dataKey: 'sessionId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    width: '10%',
    align: 'left',
    dataKey: 'channelName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    width: '10%',
    align: 'left',
    dataKey: 'platform',
    sortable: true,
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'video',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR}`,
    width: '15%',
    align: 'left',
    dataKey: 'videoTitle',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'device',
    label: 'Device UID',
    width: '10%',
    align: 'left',
    dataKey: 'deviceId',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    width: '10%',
    align: 'left',
    dataKey: 'ip',
    sortable: false,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartTime',
    label: 'Session Start Time',
    width: '15%',
    align: 'left',
    dataKey: 'sessionStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'watchedSeconds',
    label: 'Total Watched',
    width: '10%',
    align: 'right',
    dataKey: 'totalSecondsWatch',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
];

export const VIEW_WATCH_SESSIONS = [
  {
    id: 'sessionId',
    label: 'Session ID',
    dataKey: 'sessionId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'channel',
    label: 'Channel',
    dataKey: 'channelName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'platform',
    label: 'Platform',
    dataKey: 'platform',
    type: CELL_DATA_TYPES.PLATFORM,
  },
  {
    id: 'videoTitle',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR}`,
    dataKey: 'videoTitle',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'device',
    label: 'Device UID',
    dataKey: 'deviceId',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'ip',
    label: 'IP Address',
    dataKey: 'ip',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sessionStartTime',
    label: 'Session Start Time',
    dataKey: 'sessionStartedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },

  {
    id: 'sessionEndedAt',
    label: 'Session End Time',
    dataKey: 'sessionEndedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'videoPlayedFrom',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} Played From`,
    dataKey: 'videoPlayedFrom',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'videoWatchProgress',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} Watched Till`,
    dataKey: 'videoWatchProgress',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'watchedSeconds',
    label: 'Total Watched',
    dataKey: 'totalSecondsWatch',
    type: CELL_DATA_TYPES.DURATION,
  },
];
