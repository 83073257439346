/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';
import {
  Card,
  Stack,
  Button,
  Box,
  Container,
  CardContent,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Tooltip,
} from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Iconify from '../common/iconify.component';
import {
  getDataList,
  viewChannelById,
  viewVideoById,
} from '../../services/content.service';
import { formatDuration } from '../../utils/numbers.util';
import { PRIMARY } from '../../theme/theme-colors';
import AddEditEpg from './add-edit-epg.component';
import Datepicker from '../form-elements/datepicker.component';
import {
  convertDateStringToTimestamp,
  convertTimestampToDate,
  formatDateToUtc,
  getDate,
} from '../../utils/datetime.util';
import {
  checkEpgExistsInTimerange,
  createNewEpg,
  deleteEpgById,
  getListEpg,
  updateEpgById,
} from '../../services/epg.service';
import ConfirmPopup from '../common/confirm-popup.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Categories from '../form-elements/categories.component';
import {
  CONTENT_ALIAS,
  EPG_SCHEDULER_GAP_HEIGHT,
  TIME_GAP_OF_EPG_BLOCK_IN_SECONDS,
} from '../../config/const.config';
import Scrollbar from '../common/scrollbar.component';
import ConflictEpgPopup from './conflict-epg-popup.component';
import {
  timeScheduler12Hours,
  timeScheduler24Hours,
} from '../../config/time-scheduler.config';

const ListEpg = () => {
  const [options, setOptions] = useState({
    page: 0,
    rowsPerPage: 10,
    totalRows: 0,
    videoRows: [],
    reloadCounter: 0,
    error: false,
    loading: true,
  });
  const [filters, setFilters] = useState({
    date: getDate(0),
    channelRows: [],
    category: null,
    searchVideo: '',
  });
  const [openPopup, setOpenPopup] = useState({
    add: false,
    edit: false,
    delete: false,
    epgConflict: false,
  });
  const [details, setDetails] = useState({
    channelId: null,
    videoId: null,
    programStartDate: getDate(0).toString().slice(0, 16),
    epgId: null,
    videoData: {},
    channelData: {},
    epgData: [],
    loading: true,
  });
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const [droppableId, setDroppableId] = useState(null);
  const [timeFormat, setTimeFormat] = useState('');
  const [apiPayload, setApiPayload] = useState({});
  const [epgProgramId, setEpgProgramId] = useState(null);
  const [autoFillStartTime, setAutoFillStartTime] = useState(null);

  const scheduledVideoHeightCalc = (epgData) => {
    let scheduledVideoHeight;

    if (filters.date.getDate() < new Date(epgData.endTime).getDate()) {
      const startTimeOfLastVideo = new Date(epgData.startTime).getTime() / 1000;
      const endTimeOfSelectedDate = Math.floor(
        filters.date.setHours(23, 59, 59) / 1000
      );

      scheduledVideoHeight =
        (Number(endTimeOfSelectedDate - startTimeOfLastVideo) *
          EPG_SCHEDULER_GAP_HEIGHT) /
        1800;
    } else if (filters.date.getDate() > new Date(epgData.startTime).getDate()) {
      const endTimeOfFirstVideo = new Date(epgData.endTime).getTime() / 1000;
      const startTimeOfSelectedDate = Math.floor(
        filters.date.setHours(0, 0, 0) / 1000
      );

      scheduledVideoHeight =
        (Number(endTimeOfFirstVideo - startTimeOfSelectedDate) *
          EPG_SCHEDULER_GAP_HEIGHT) /
        1800;
    } else {
      scheduledVideoHeight =
        (epgData.videoDuration * EPG_SCHEDULER_GAP_HEIGHT) / 1800;
    }

    return scheduledVideoHeight;
  };

  const scheduledVideoPositionCalc = (epgData) => {
    const startDate = new Date(epgData.startTime);

    const videoStartSeconds =
      startDate.getHours() * 3600 +
      startDate.getMinutes() * 60 +
      startDate.getSeconds();

    const videoStartPosition = Number(
      21 + (videoStartSeconds * EPG_SCHEDULER_GAP_HEIGHT) / 1800
    );
    return videoStartPosition;
  };

  const convertReadableProgramTime = (programData) => {
    const startDate = new Date(programData.startTime);
    const endDate = new Date(programData.endTime);

    const programTime = `${startDate.toTimeString().split(' ')[0]} to ${
      endDate.toTimeString().split(' ')[0]
    }`;

    return programTime;
  };

  const handleEpgEdit = (dataId) => {
    setOpenPopup({ ...openPopup, edit: true });
    setDetails({ ...details, epgId: dataId });
  };

  const handleOnDragEnd = async (result) => {
    if (result?.destination) {
      setOpenPopup({ ...openPopup, add: true });
      setDroppableId(result.destination?.droppableId);
    }

    const videoId = result?.draggableId;
    const channelId = 1;
    try {
      const channelDetails = await viewChannelById(channelId);
      const videoDetails = await viewVideoById(videoId);
      setDetails({
        ...details,
        channelId,
        videoId,
        channelData: channelDetails.data,
        videoData: videoDetails.data,
      });
    } catch (e) {
      // nothing
    }

    if (result.destination?.droppableId) {
      const calcStartTime = formatDateToUtc(
        `${filters.date.toString().substring(0, 16)}${
          timeScheduler24Hours[result.destination.droppableId - 1]
        }:00${filters.date.toString().substring(24)}`
      );

      const calcEndTime = formatDateToUtc(
        `${filters.date.toString().substring(0, 16)}${
          timeScheduler24Hours[result.destination.droppableId]
        }:00${filters.date.toString().substring(24)}`
      );

      const localStartTimeStamp = new Date(calcStartTime).getTime() / 1000;
      const localEndTimeStamp =
        result.destination.droppableId < 48
          ? new Date(calcEndTime).getTime() / 1000
          : localStartTimeStamp + TIME_GAP_OF_EPG_BLOCK_IN_SECONDS;

      checkEpgExistsInTimerange(localStartTimeStamp, localEndTimeStamp)
        .then((res) => {
          setAutoFillStartTime(
            res.data.endedAt &&
              formatDateToUtc(
                new Date(convertTimestampToDate(res.data.endedAt))
              )
          );
        })
        .catch(() => {
          // nothing
        });
    }
  };

  const handleRefreshData = () => {
    setOptions({
      ...options,
      reloadCounter: options.reloadCounter + 1,
    });
  };

  const handleSelectDate = (v) => {
    setDetails({
      ...details,
      programStartDate: v.$d.toString().slice(0, 16),
      loading: true,
    });
    setFilters({
      ...filters,
      date: v.$d,
    });
  };

  const deleteAction = () => {
    setOpenPopup({ ...openPopup, delete: true });
  };

  const handleActionCancel = () => {
    setOpenPopup({ ...openPopup, add: false });
  };

  const handleEditActionCancel = () => {
    setOpenPopup({ ...openPopup, edit: false });
  };

  const handleDeleteActionCancel = () => {
    setOpenPopup({ ...openPopup, delete: false });
  };

  const handleConflictEpgCancel = () => {
    setOpenPopup({ ...openPopup, epgConflict: false });
  };

  const handleActionError = (message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    setTimeout(() => {
      setSnackbarInfo({ ...snackbarInfo, show: true, type: 'error', message });
    }, 250);
  };

  const handleActionSuccess = (message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }, 250);

    handleActionCancel();
    handleRefreshData();
  };

  const handleEditActionSuccess = (message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }, 250);

    handleEditActionCancel();
    handleRefreshData();
  };

  const handleEpgScheduledMsg = (message) => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    setTimeout(() => {
      setSnackbarInfo({
        ...snackbarInfo,
        show: true,
        type: 'success',
        message,
      });
    }, 250);

    setOpenPopup({ ...openPopup, add: false, edit: false, epgConflict: false });
    handleRefreshData();
  };

  const conflictPopupCallback = (epgPayload, dataId = null) => {
    setOpenPopup({ ...openPopup, epgConflict: true });

    const payload = { ...epgPayload, conflictConfirm: false };
    setApiPayload(payload);
    setEpgProgramId(dataId);
  };

  const handleConflictEpgSuccess = () => {
    const payload = { ...apiPayload, conflictConfirm: true };

    if (epgProgramId) {
      updateEpgById(epgProgramId, payload)
        .then((res) => {
          if (res.data.error) {
            conflictPopupCallback(payload, epgProgramId);
          } else if (res.data.success) {
            handleEpgScheduledMsg('EPG details updated successfully.');
          }
        })
        .catch((e) => {
          handleActionError(
            e.response?.data?.message ||
              'Something went wrong. Please try again!'
          );
        });
    } else {
      createNewEpg(payload)
        .then((res) => {
          if (res.data.error) {
            conflictPopupCallback();
          } else if (res.data.success) {
            handleEpgScheduledMsg('EPG scheduled successfully.');
          }
        })
        .catch((e) => {
          handleActionError(
            e.response?.data?.message ||
              'Something went wrong. Please try again!'
          );
        });
    }
  };

  const handleDeleteData = () => {
    setSnackbarInfo({ ...snackbarInfo, show: false });

    deleteEpgById(details.epgId)
      .then((res) => {
        if (res.data?.success) {
          setOpenPopup({ ...openPopup, delete: false, edit: false });
          setSnackbarInfo({
            ...snackbarInfo,
            show: true,
            type: 'success',
            message: 'EPG removed successfully.',
          });
        } else {
          // Something went wrong
        }
      })
      .catch(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Something went wrong. Please try again!',
        });
      });

    handleRefreshData();
  };

  const handleSearchProgram = (e) => {
    setFilters({ ...filters, searchVideo: e.target.value });
  };

  const handleCategoryChange = (e) => {
    setFilters({ ...filters, category: e.target.value });
  };

  useEffect(() => {
    setOptions({ ...options, loading: true, videoRows: [], totalRows: 0 });

    // Videos listing API call
    let params = 'videos';
    params += `?category=${filters.category}`;
    params += `&q=${filters.searchVideo}`;
    getDataList(params)
      .then((res) => {
        setOptions({
          ...options,
          loading: false,
          totalRows: res?.data?.totalRows || 0,
          videoRows: res?.data?.rows || [],
          error: false,
        });
      })
      .catch(() => {
        setOptions({
          ...options,
          loading: false,
          page: 0,
          totalRows: 0,
          videoRows: [],
          error: true,
        });
      });
  }, [filters.category, filters.searchVideo]);

  useEffect(() => {
    setDetails({ ...details, loading: true });

    // Channels listing API call
    const uri = 'channels';
    getDataList(uri)
      .then((res) => {
        setFilters({ ...filters, channelRows: res.data.rows });
      })
      .catch(() => {
        setFilters({ ...filters, channelRows: [] });
      });

    // Converted local time to GMT timestamp
    const localStartDate = new Date(`${details.programStartDate} 00:00:00`);
    const localEndDate = new Date(`${details.programStartDate} 23:59:59`);

    const startTime = convertDateStringToTimestamp(localStartDate);
    const endTime = convertDateStringToTimestamp(localEndDate);

    // EPG listing API call
    getListEpg(startTime, endTime)
      .then((res) => {
        setDetails({ ...details, epgData: res.data?.rows, loading: false });
      })
      .catch(() => {
        setDetails({ ...details, epgData: [], loading: false });
      });
  }, [options.reloadCounter, details.programStartDate]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: '16px', py: '10px' }}
      >
        <Stack>
          <Box>
            <Datepicker
              id="dr_date"
              name="dr_date"
              label="Select Date"
              defaultValue={filters.date}
              onChange={handleSelectDate}
              sx={{
                width: 150,
                backgroundColor: 'white',
                borderRadius: '8px',
              }}
            />
          </Box>
        </Stack>

        <Stack direction="row" sx={{ px: 1, py: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="field-time_format-select-label">
              Select Time Format
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="time_format"
              name="time_format"
              label="Select Time Format"
              defaultValue="am-pm"
              onChange={(e) => setTimeFormat(e.target.value)}
              sx={{
                height: '40px',
                width: '200px',
              }}
            >
              <MenuItem key="1" value="am-pm">
                AM / PM
              </MenuItem>
              <MenuItem key="1" value="24-hours">
                24 Hours
              </MenuItem>
            </Select>
          </FormControl>

          <Button
            color="inherit"
            startIcon={<Iconify icon="ic:twotone-refresh" />}
            onClick={handleRefreshData}
            disabled={options.loading || details.loading}
            sx={{ marginX: 2 }}
          >
            Refresh
          </Button>
        </Stack>
      </Stack>

      <Card
        sx={{ display: 'flex', backgroundColor: '#DEDEDE', width: '1470px' }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Box
            className="epg-video-list-container"
            sx={{
              height: 710,
              minWidth: 300,
              maxWidth: 400,
              width: 400,
              padding: '10px 5px 10px 5px',
              marginLeft: '20px',
              marginTop: '15px',
            }}
          >
            <Box className="epg-video-list" sx={{ height: 500 }}>
              <Box
                className="search-video"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '5px 1px 5px 1px',
                }}
              >
                <Categories
                  id="category"
                  name="category"
                  label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                  defaultValue={filters.category}
                  onChange={handleCategoryChange}
                  sx={{
                    width: '100%',
                    height: '56px',
                    backgroundColor: '#E5E5E5',
                    borderRadius: '10px',
                  }}
                  options={options}
                  allowAllOptions
                />
              </Box>

              <Box
                className="search-video"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '6px 1px 5px 1px',
                  borderRadius: '55px',
                }}
              >
                <TextField
                  id="outlined-basic"
                  label="Search Program"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  sx={{
                    backgroundColor: '#E5E5E5',
                    borderRadius: '10px',
                    width: '100%',
                  }}
                  onChange={handleSearchProgram}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton edge="end" color="black">
                          <Iconify icon="fe:search" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Divider
                variant="fullWidth"
                sx={{
                  marginTop: 1.5,
                  marginBottom: 2,
                }}
              />

              <Box className="video-list" sx={{ overflow: 'hidden' }}>
                <Container
                  maxWidth="false"
                  sx={{
                    height: '518px',
                    paddingY: 1,
                    backgroundColor: PRIMARY.main,
                    borderRadius: '10px',
                  }}
                >
                  <Scrollbar
                    sx={{
                      height: 1,
                      '& .simplebar-content': {
                        height: 1,
                        display: 'flex',
                        flexDirection: 'column',
                      },
                    }}
                  >
                    <Droppable droppableId="videos" isDropDisabled>
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {options.loading && <Loading loaderColor="black" />}

                          {!options.loading &&
                            options.videoRows.length > 0 &&
                            options.videoRows.map((item, index) => (
                              <>
                                <Draggable
                                  key={`category-${item.id}`}
                                  draggableId={item.id.toString()}
                                  index={index}
                                >
                                  {(provide) => (
                                    <Card
                                      sx={{
                                        marginBottom: '3px',
                                        borderRadius: '5px',
                                      }}
                                      ref={provide.innerRef}
                                      {...provide.draggableProps}
                                      {...provide.dragHandleProps}
                                    >
                                      <CardContent
                                        sx={{
                                          display: 'flex',
                                          justifyContent: 'flex-start',
                                          alignItems: 'center',
                                          padding: '16px',
                                          '&:last-child': {
                                            paddingBottom: '12px',
                                          },
                                          height: '65px',
                                          fontSize: '14px',
                                          bgcolor: PRIMARY.main,
                                          color: PRIMARY.contrastText,
                                        }}
                                      >
                                        {item.thumbnailUrl ? (
                                          <img
                                            src={item.thumbnailUrl}
                                            alt="thumbnail"
                                            style={{
                                              width: '80px',
                                              height: '45px',
                                              objectFit: 'contain',
                                              borderRadius: '5px',
                                              minWidth: '80px',
                                            }}
                                          />
                                        ) : (
                                          <div
                                            style={{
                                              width: '80px',
                                              height: '45px',
                                              objectFit: 'contain',
                                              borderRadius: '5px',
                                              minWidth: '80px',
                                              backgroundColor: '#3b3838',
                                            }}
                                          />
                                        )}
                                        <Tooltip
                                          title={
                                            <span>
                                              {[
                                                <div
                                                  style={{ fontSize: '12px' }}
                                                >
                                                  {item.title || ''}
                                                </div>,
                                                <div
                                                  style={{
                                                    color: PRIMARY.main,
                                                    fontSize: '10px',
                                                  }}
                                                >
                                                  {item.tags || ''}
                                                </div>,
                                              ]}
                                            </span>
                                          }
                                          arrow
                                        >
                                          <div
                                            style={{
                                              marginLeft: '10px',
                                              fontSize: '16px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                            }}
                                          >
                                            <b>{`${item.title}`}</b>

                                            <br />
                                            {`${formatDuration(
                                              item.duration
                                            )} ${
                                              item.type === 'AD'
                                                ? `\u25CF Advertise`
                                                : ''
                                            }`}
                                          </div>
                                        </Tooltip>
                                      </CardContent>
                                    </Card>
                                  )}
                                </Draggable>
                                {index === options.videoRows.length - 1 ? (
                                  <div />
                                ) : (
                                  <hr
                                    style={{
                                      border: '1px dashed #757272',
                                      margin: '4px 0px',
                                    }}
                                  />
                                )}
                              </>
                            ))}

                          {!options.loading &&
                            options.videoRows.length === 0 && (
                              <Box
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  paddingTop: '30px',
                                  fontWeight: 'bold',
                                }}
                              >
                                No data found.
                              </Box>
                            )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Scrollbar>
                </Container>
              </Box>
            </Box>
          </Box>

          {details.loading && (
            <Loading
              style={{
                minWidth: '1000px',
                maxWidth: '1000px',
                height: '660px',
                position: 'absolute',
                left: '440px',
                top: '32px',
                zIndex: 1,
              }}
            />
          )}

          <Box
            sx={{
              backgroundColor: '#EBEAE4',
              minWidth: '1000px',
              maxWidth: '1000px',
              height: '660px',
              position: 'absolute',
              left: '440px',
              top: '32px',
              overflowY: 'scroll',
              padding: '20px',
            }}
          >
            <Box
              className="schedule-time"
              sx={{
                display: 'inline-block',
                width: '75px',
                marginLeft: '40px',
                position: 'relative',
                top: `${8 - EPG_SCHEDULER_GAP_HEIGHT}px`,
              }}
            >
              {timeFormat === '24-hours'
                ? timeScheduler24Hours.map((item) => (
                    <Box
                      sx={{
                        height: `${EPG_SCHEDULER_GAP_HEIGHT}px`,
                        width: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {item}
                    </Box>
                  ))
                : timeScheduler12Hours.map((item) => (
                    <Box
                      sx={{
                        height: `${EPG_SCHEDULER_GAP_HEIGHT}px`,
                        width: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {item}
                    </Box>
                  ))}
            </Box>

            <Box
              className="schedule-column"
              sx={{
                height: `${EPG_SCHEDULER_GAP_HEIGHT * 48}px`,
                width: '10px',
                border: '1px solid black',
                display: 'inline-block',
              }}
            />

            <Box
              className="scheduler"
              sx={{
                display: 'inline-block',
                height: `${EPG_SCHEDULER_GAP_HEIGHT * 48}px`,
              }}
            >
              {timeScheduler24Hours.map((d, index) => (
                <Droppable droppableId={`${index + 1}`} isDropDisabled={false}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={{
                        backgroundColor: snapshot.isDraggingOver
                          ? 'grey'
                          : '#EBEAE4',
                        width: '800px',
                        borderTop: '1px dashed #aaa',
                        borderBottom: index === 47 ? '1px dashed #aaa' : '',
                      }}
                      {...provided.droppableProps}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          height: `${EPG_SCHEDULER_GAP_HEIGHT - 1}px`,
                          width: '760px',
                          position: 'relative',
                          left: '40px',
                          borderRadius: '5px',
                        }}
                      />
                    </div>
                  )}
                </Droppable>
              ))}

              {details.epgData.map((item) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: `${scheduledVideoHeightCalc(item)}px`,
                    width: '760px',
                    backgroundColor:
                      item.type === 'VIDEO' ? PRIMARY.main : 'skyblue',
                    position: 'absolute',
                    left: '180px',
                    top: `${
                      filters.date.getDate() >
                      new Date(item.startTime).getDate()
                        ? 21
                        : scheduledVideoPositionCalc(item)
                    }px`,
                    borderRadius: '5px',
                    borderTopLeftRadius:
                      filters.date.getDate() >
                        new Date(item.startTime).getDate() && 0,
                    borderTopRightRadius:
                      filters.date.getDate() >
                        new Date(item.startTime).getDate() && 0,
                    borderBottomLeftRadius:
                      filters.date.getDate() <
                        new Date(item.endTime).getDate() && 0,
                    borderBottomRightRadius:
                      filters.date.getDate() <
                        new Date(item.endTime).getDate() && 0,
                    cursor: 'pointer',
                    border: '0.5px solid black',
                    borderTop:
                      filters.date.getDate() >
                        new Date(item.startTime).getDate() && 0,
                    borderBottom:
                      filters.date.getDate() <
                        new Date(item.endTime).getDate() && 0,
                    minHeight: '15px',
                    fontSize: `${item.videoDuration < 420 ? '12px' : '16px'}`,
                  }}
                  onClick={() => handleEpgEdit(item.id)}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      margin: '0px 0px 0px 20px',
                    }}
                  >
                    <b>{item.videoTitle}</b>
                  </Box>
                  <Box
                    sx={{
                      height: '15px',
                      width: '2px',
                      backgroundColor: 'black',
                      margin: '0px 10px',
                    }}
                  />
                  <Box
                    sx={{
                      display: 'inline-block',
                    }}
                  >
                    <b>{`${convertReadableProgramTime(item)} `}</b>
                    {`(${formatDuration(item.videoDuration)})`}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </DragDropContext>
      </Card>

      {openPopup.add && (
        <AddEditEpg
          title="Manage EPG - Add"
          dataId={0}
          onCancel={handleActionCancel}
          onSuccess={handleActionSuccess}
          onError={handleActionError}
          filters={filters}
          details={details}
          droppableId={droppableId}
          conflictPopupCallback={conflictPopupCallback}
          passedStartTime={autoFillStartTime}
        />
      )}

      {openPopup.edit && (
        <AddEditEpg
          title="Manage EPG - Edit"
          dataId={details.epgId}
          onCancel={handleEditActionCancel}
          onSuccess={handleEditActionSuccess}
          onError={handleActionError}
          filters={filters}
          details={details}
          deleteAction={deleteAction}
          conflictPopupCallback={conflictPopupCallback}
          passedStartTime={autoFillStartTime}
        />
      )}

      {openPopup.delete && (
        <ConfirmPopup
          title="Delete EPG"
          message="Do you want to delete EPG? You can not undo this action!"
          onCancel={handleDeleteActionCancel}
          onSuccess={handleDeleteData}
        />
      )}

      {openPopup.epgConflict && (
        <ConflictEpgPopup
          title="EPG Conflict"
          message="Your EPG is conflict with other EPG. Do you want to overlap EPG? You can not undo this action!"
          onCancel={handleConflictEpgCancel}
          onSuccess={handleConflictEpgSuccess}
        />
      )}

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </>
  );
};

export default ListEpg;
