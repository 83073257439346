import React, { forwardRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const SnackbarInfo = ({ type, message, autoHideMessage }) => {
  const [open, setOpen] = useState(true);

  const handleAutoClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={open}
        autoHideDuration={autoHideMessage ? 5000 : null}
        onClose={handleAutoClose}
      >
        <Alert severity={type} onClose={handleAutoClose}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

SnackbarInfo.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  autoHideMessage: PropTypes.bool,
};

SnackbarInfo.defaultProps = {
  autoHideMessage: true,
};

export default SnackbarInfo;
