import React from 'react';
import PropTypes from 'prop-types';
import AppLayout from '../../layout/app.layout';
import ListCategories from '../../components/content/list-categories.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentCategoriesView = ({
  categoryTypeSlug,
  categoryTypeName,
  categoryTypeId,
}) => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_CATEGORY}${
    categoryTypeSlug ? ` - ${categoryTypeName}` : ''
  }`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCategories
        categoryTypeSlug={categoryTypeSlug}
        categoryTypeName={categoryTypeName}
        categoryTypeId={categoryTypeId}
      />
    </AppLayout>
  );
};

ContentCategoriesView.propTypes = {
  categoryTypeSlug: PropTypes.string,
  categoryTypeName: PropTypes.string,
  categoryTypeId: PropTypes.number,
};
ContentCategoriesView.defaultProps = {
  categoryTypeSlug: '',
  categoryTypeName: '',
  categoryTypeId: null,
};

export default ContentCategoriesView;
