export const timeScheduler24Hours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

export const timeScheduler12Hours = [
  '00:00 AM',
  '00:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

// Temporary added

// export const schedulerTime = [
//   {
//     '24HoursClock': '00:00',
//     '12HoursClock': '00:00 AM',
//   },
//   {
//     '24HoursClock': '00:30',
//     '12HoursClock': '00:30 AM',
//   },
//   {
//     '24HoursClock': '01:00',
//     '12HoursClock': '01:00 AM',
//   },
//   {
//     '24HoursClock': '01:30',
//     '12HoursClock': '01:30 AM',
//   },
//   {
//     '24HoursClock': '02:00',
//     '12HoursClock': '02:00 AM',
//   },
//   {
//     '24HoursClock': '02:30',
//     '12HoursClock': '02:30 AM',
//   },
//   {
//     '24HoursClock': '03:00',
//     '12HoursClock': '03:00 AM',
//   },
//   {
//     '24HoursClock': '03:30',
//     '12HoursClock': '03:30 AM',
//   },
//   {
//     '24HoursClock': '04:00',
//     '12HoursClock': '04:00 AM',
//   },
//   {
//     '24HoursClock': '04:30',
//     '12HoursClock': '04:30 AM',
//   },
//   {
//     '24HoursClock': '05:00',
//     '12HoursClock': '05:00 AM',
//   },
//   {
//     '24HoursClock': '05:30',
//     '12HoursClock': '05:30 AM',
//   },
//   {
//     '24HoursClock': '06:00',
//     '12HoursClock': '06:00 AM',
//   },
//   {
//     '24HoursClock': '06:30',
//     '12HoursClock': '06:30 AM',
//   },
//   {
//     '24HoursClock': '07:00',
//     '12HoursClock': '07:00 AM',
//   },
//   {
//     '24HoursClock': '07:30',
//     '12HoursClock': '07:30 AM',
//   },
//   {
//     '24HoursClock': '08:00',
//     '12HoursClock': '08:00 AM',
//   },
//   {
//     '24HoursClock': '08:30',
//     '12HoursClock': '08:30 AM',
//   },
//   {
//     '24HoursClock': '09:00',
//     '12HoursClock': '09:00 AM',
//   },
//   {
//     '24HoursClock': '09:30',
//     '12HoursClock': '09:30 AM',
//   },
//   {
//     '24HoursClock': '10:00',
//     '12HoursClock': '10:00 AM',
//   },
//   {
//     '24HoursClock': '10:30',
//     '12HoursClock': '10:30 AM',
//   },
//   {
//     '24HoursClock': '11:00',
//     '12HoursClock': '11:00 AM',
//   },
//   {
//     '24HoursClock': '11:30',
//     '12HoursClock': '11:30 AM',
//   },
//   {
//     '24HoursClock': '12:00',
//     '12HoursClock': '12:00 PM',
//   },
//   {
//     '24HoursClock': '12:30',
//     '12HoursClock': '12:30 PM',
//   },
//   {
//     '24HoursClock': '13:00',
//     '12HoursClock': '01:00 PM',
//   },
//   {
//     '24HoursClock': '13:30',
//     '12HoursClock': '01:30 PM',
//   },
//   {
//     '24HoursClock': '14:00',
//     '12HoursClock': '02:00 PM',
//   },
//   {
//     '24HoursClock': '14:30',
//     '12HoursClock': '02:30 PM',
//   },
//   {
//     '24HoursClock': '15:00',
//     '12HoursClock': '03:00 PM',
//   },
//   {
//     '24HoursClock': '15:30',
//     '12HoursClock': '03:30 PM',
//   },
//   {
//     '24HoursClock': '16:00',
//     '12HoursClock': '04:00 PM',
//   },
//   {
//     '24HoursClock': '16:30',
//     '12HoursClock': '04:30 PM',
//   },
//   {
//     '24HoursClock': '17:00',
//     '12HoursClock': '05:00 PM',
//   },
//   {
//     '24HoursClock': '17:30',
//     '12HoursClock': '05:30 PM',
//   },
//   {
//     '24HoursClock': '18:00',
//     '12HoursClock': '06:00 PM',
//   },
//   {
//     '24HoursClock': '18:30',
//     '12HoursClock': '06:30 PM',
//   },
//   {
//     '24HoursClock': '19:00',
//     '12HoursClock': '07:00 PM',
//   },
//   {
//     '24HoursClock': '19:30',
//     '12HoursClock': '07:30 PM',
//   },
//   {
//     '24HoursClock': '20:00',
//     '12HoursClock': '08:00 PM',
//   },
//   {
//     '24HoursClock': '20:30',
//     '12HoursClock': '08:30 PM',
//   },
//   {
//     '24HoursClock': '21:00',
//     '12HoursClock': '09:00 PM',
//   },
//   {
//     '24HoursClock': '21:30',
//     '12HoursClock': '09:30 PM',
//   },
//   {
//     '24HoursClock': '22:00',
//     '12HoursClock': '10:00 PM',
//   },
//   {
//     '24HoursClock': '22:30',
//     '12HoursClock': '10:30 PM',
//   },
//   {
//     '24HoursClock': '23:00',
//     '12HoursClock': '11:00 PM',
//   },
//   {
//     '24HoursClock': '23:30',
//     '12HoursClock': '11:30 PM',
//   },
// ];
