import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
  minLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Status from '../form-elements/status.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/admin-users.service';
import Categories from '../form-elements/categories.component';
import { CONTENT_ALIAS } from '../../config/const.config';
import Roles from '../form-elements/roles.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditUser = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      category: null,
      name: '',
      email: '',
      username: '',
      password: '',
      status: null,
      role: '',
    },
  });

  const watchFields = watch(['role']);

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New user created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('User details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      category: watchFields[0] === 'ADMIN' ? null : data.category,
      name: data.name,
      email: data.email,
      username: data.username,
      role: data.role,
    };
    if (dataId) {
      payload.status = Number(data.status);

      updateExistingData(payload);
    } else {
      payload.password = data.password;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('category', res.data?.categoryId || null);
        setValue('name', res.data?.name || '');
        setValue('email', res.data?.email || '');
        setValue('username', res.data?.username || '');
        setValue('status', res.data?.status || 0);
        setValue('role', res.data?.role || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(200) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Full Name"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(100) },
                    ...{ ...minLengthValidation(3) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Username"
                      type="text"
                      error={!!errors.username}
                      helperText={errors?.username?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED_EMAIL,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="text"
                      error={!!errors.email}
                      helperText={errors?.email?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {dataId === 0 && (
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...validationRules.PASSWORD,
                      ...{ ...maxLengthValidation(100) },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        error={!!errors.password}
                        helperText={errors?.password?.message || null}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}

                {dataId !== 0 && (
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="role"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Roles
                      id="role"
                      name="role"
                      label="Select Role"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={['ADMIN', 'CONTENT_OWNER']}
                      sx={{ width: '100%' }}
                      error={errors?.role?.message || ''}
                    />
                  )}
                />
              </Grid>

              {watchFields[0] === 'CONTENT_OWNER' && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="category"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Categories
                        id="category"
                        name="category"
                        label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                        defaultValue={value}
                        multiple={false}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.category?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditUser.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditUser;
