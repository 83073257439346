import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const Roles = ({
  id,
  name,
  defaultValue,
  label,
  onChange,
  validOptions,
  sx,
  error,
}) => (
  <FormControl fullWidth error={error !== ''}>
    <InputLabel id={`field-${id}-select-label`}>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id={id}
      name={name}
      value={defaultValue}
      label={label}
      onChange={onChange}
      sx={sx}
    >
      {validOptions.map((item) => (
        <MenuItem key={`${id}-v-${item}`} value={item}>
          {item}
        </MenuItem>
      ))}
    </Select>
    {error !== '' && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

Roles.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  validOptions: PropTypes.string.isRequired,
  sx: PropTypes.shape(),
  error: PropTypes.string,
};
Roles.defaultProps = {
  sx: {},
  error: '',
};

export default Roles;
