import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Divider,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import {
  BtnBold,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  BtnUnderline,
  Editor,
  EditorProvider,
  Toolbar,
} from 'react-simple-wysiwyg';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewVideo,
  updateVideoById,
  viewVideoById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import Categories from '../form-elements/categories.component';
import {
  CONTENT_ALIAS,
  DEFAULT_CHANNEL_VALUE,
  LS_ADMIN_USER_ID,
  MULTIPLE_CAT_PER_VIDEO,
} from '../../config/const.config';
import { getLocalValue } from '../../utils/local-cache.util';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditVideos = ({
  title,
  dataId,
  onCancel,
  onSuccess,
  options,
  videoContentType,
  adminUserRole,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      category: '',
      description: '',
      longDescription: '',
      featuring: '',
      tags: '',
      status: adminUserRole === 'CONTENT_OWNER' ? 0 : null,
      duration: null,
      videoUrl: '',
      contentType: '',
      thumbnail: '',
      heroImage: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewVideo(payload)
      .then(() => {
        onSuccess(
          `New ${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } added successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateVideoById(dataId, payload)
      .then(() => {
        onSuccess(
          `${
            videoContentType === 'VIDEO'
              ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
              : 'Ad'
          } details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const categoriesIdStrArr = [];
    if (videoContentType !== 'AD') {
      if (!MULTIPLE_CAT_PER_VIDEO) {
        categoriesIdStrArr.push(data.category);
      } else if (MULTIPLE_CAT_PER_VIDEO) {
        if (typeof data.categories === 'object') {
          data.categories.forEach((item) => {
            const spiltData = item.split('] -');
            const categoryId = spiltData[0].slice(1);

            categoriesIdStrArr.push(`${categoryId}`);
          });
        }
      }
    }

    const payload = {
      title: data.title || '',
      description: data.description || '',
      category: categoriesIdStrArr || [],
      channelId: DEFAULT_CHANNEL_VALUE,
      longDescription: data.longDescription || '',
      featuring: data.featuring || '',
      tags: data.tags || '',
      status: data.status,
      duration: data.duration || 0,
      videoUrl: data.videoUrl || '',
      videoType: videoContentType,
      thumbnail: data.thumbnail || '',
      heroImage: data.heroImage || '',
      multipleCatPerVideo: MULTIPLE_CAT_PER_VIDEO,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      const adminUserId = getLocalValue(LS_ADMIN_USER_ID);
      payload.adminUserId = adminUserId || null;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewVideoById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('category', res.data?.category[0]?.categoryId || null);
        setValue('longDescription', res.data?.longDescription || '');
        setValue('featuring', res.data?.featuring || '');
        setValue('tags', res.data?.tags || '');
        setValue('status', res.data?.status || 0);
        setValue('duration', res.data?.duration || 0);
        setValue('videoUrl', res.data?.videoUrl || '');
        setValue('thumbnail', res.data?.thumbnailUrl || '');
        setValue('heroImage', res.data?.heroImageUrl || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${
                        videoContentType === 'VIDEO'
                          ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
                          : 'Ad'
                      } Title`}
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="description"
                  control={control}
                  rules={{ ...maxLengthValidation(250) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {videoContentType === 'VIDEO' && !MULTIPLE_CAT_PER_VIDEO && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="category"
                    control={control}
                    rules={
                      adminUserRole === 'CONTENT_OWNER'
                        ? validationRules.REQUIRED
                        : ''
                    }
                    render={({ field: { onChange, value } }) => (
                      <Categories
                        id="category"
                        name="category"
                        label={`Select ${CONTENT_ALIAS.CATEGORY_SINGULAR}`}
                        defaultValue={value}
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        error={errors?.category?.message || ''}
                        options={options}
                        showSelectCatOption={adminUserRole === 'ADMIN'}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="longDescription"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <EditorProvider>
                      <Editor value={value} onChange={onChange}>
                        <Toolbar>
                          <Box
                            padding="0px 8px"
                            color="#637381"
                            borderRight="1px solid #637381"
                            fontSize="15px"
                          >
                            Long Description (Display on Website Only)
                          </Box>
                          <BtnBold />
                          <BtnItalic />
                          <BtnUnderline />
                          <BtnStrikeThrough />
                          <BtnNumberedList />
                          <BtnLink />
                          <BtnClearFormatting />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="featuring"
                  control={control}
                  rules={{ ...maxLengthValidation(500) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Featuring"
                      type="text"
                      error={!!errors.featuring}
                      helperText={errors?.featuring?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="tags"
                  control={control}
                  rules={{ ...maxLengthValidation(500) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Tags"
                      type="text"
                      error={!!errors.tags}
                      helperText={errors?.tags?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {adminUserRole === 'ADMIN' && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[1, 0]}
                        isDisabled={adminUserRole === 'CONTENT_OWNER'}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={12} md={12}>
                <Divider
                  textAlign="center"
                  variant="fullWidth"
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {`${
                    videoContentType === 'VIDEO'
                      ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
                      : 'Ad'
                  } Details (You can upload ${
                    videoContentType === 'VIDEO' ? 'video' : 'ad'
                  } and images from upload section)`}
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="videoUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${
                        videoContentType === 'VIDEO'
                          ? `${CONTENT_ALIAS.VIDEO_SINGULAR}`
                          : 'Ad'
                      } Url`}
                      type="text"
                      error={!!errors.videoUrl}
                      helperText={errors?.videoUrl?.message || null}
                      fullWidth
                      disabled={dataId}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="duration"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Duration (In Seconds)"
                      type="number"
                      error={!!errors.duration}
                      helperText={errors?.duration?.message || null}
                      fullWidth
                      disabled={dataId}
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} sm={12} md={6}>
                <Controller
                  name="contentType"
                  control={control}
                  rules={{ ...maxLengthValidation(8) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Content Type"
                      type="text"
                      error={!!errors.contentType}
                      helperText={errors?.contentType?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 8 }}
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={6} />

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="thumbnail"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Thumbnail Url"
                      type="text"
                      error={!!errors.thumbnail}
                      helperText={errors?.thumbnail?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="heroImage"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(250) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Hero Image Url"
                      type="text"
                      error={!!errors.heroImage}
                      helperText={errors?.heroImage?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditVideos.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  defaultBooksValue: PropTypes.string.isRequired,
  videoContentType: PropTypes.string.isRequired,
  adminUserRole: PropTypes.string.isRequired,
};

export default AddEditVideos;
