import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@mui/material';
import ROUTE_PATH from '../../config/routes.config';
import logoImage from '../../assets/images/logo/gan_big_logo.png';

const Logo = ({ disabledLink, sx }) => {
  if (disabledLink) {
    return (
      <Box component="img" src={logoImage} sx={{ cursor: 'pointer', ...sx }} />
    );
  }

  return (
    <Link
      to={ROUTE_PATH.DASHBOARD}
      component={RouterLink}
      sx={{ display: 'contents' }}
    >
      <Box component="img" src={logoImage} sx={{ cursor: 'pointer', ...sx }} />
    </Link>
  );
};

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};
Logo.defaultProps = {
  sx: {},
  disabledLink: true,
};

export default Logo;
